import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				 AutoCare Pro
			</title>
			<meta name={"description"} content={"Ми в AutoCare Pro розуміємо, що ваш автомобіль – це не просто транспортний засіб, а важлива частина вашого життя. Наша місія – забезпечити його найкращим обслуговуванням, зосередившись на якості, надійності та індивідуальному підході до кожного клієнта.\n"} />
			<meta property={"og:title"} content={" AutoCare Pro"} />
			<meta property={"og:description"} content={"Ми в AutoCare Pro розуміємо, що ваш автомобіль – це не просто транспортний засіб, а важлива частина вашого життя. Наша місія – забезпечити його найкращим обслуговуванням, зосередившись на якості, надійності та індивідуальному підході до кожного клієнта.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});